import React from "react"

import styled from 'styled-components'
import * as style from '../components/style'

const Footer = styled.footer`
  width: 100%;
  background: ${style.colors.primary};
  color: ${style.colors.light};
  padding: ${style.spacing.basic}
`;

const FooterSection = () => (
  <Footer>
    Copyright © {new Date().getFullYear()} Cross Masters
  </Footer>
)

export default FooterSection
