export const colors = {
  action: 'rgb(255, 189, 16)',
  primary: 'rgb(30, 53, 99)',
  text: 'rgb(30, 30, 30)',
  light: 'rgba(255, 255, 255, 1)',
  lightGrey: 'rgb(248, 248, 248)',
  grey: 'rgb(222, 222, 222)',
  success: 'rgb(0, 153, 66)'
}

export const spacing = {
  basic: '1.5rem',
  large: '3rem',
  small: '1rem',
  smallHalf: '.5rem'
}

export const lineHeight = {
  basic: '1.4',
  small: '1.3',
  large: '1.5'
}

export const subHeading = `
  color: ${colors.action};
  font-weight: 900;
  font-size: 2rem;
  margin-bottom: ${spacing.large};
  width: 100%;
  text-align: center;
`